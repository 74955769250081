import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconTelegram: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.74023 14.8632L9.39285 19.8888C9.88986 19.8888 10.1051 19.6692 10.3632 19.4055L12.6934 17.115L17.5218 20.7519C18.4073 21.2595 19.0312 20.9922 19.2701 19.914L22.4394 4.63926L22.4403 4.63836C22.7212 3.29196 21.9669 2.76547 21.1041 3.09576L2.47493 10.4316C1.20352 10.9392 1.22277 11.6682 2.2588 11.9985L7.02154 13.5222L18.0844 6.40235C18.6051 6.04775 19.0785 6.24395 18.6891 6.59855L9.74023 14.8632Z"
      fill="currentColor"
    />
  </BaseIcon>
);
