import React from 'react';
import { NextPage } from 'next';
import { GetInitialProps, PageProps } from '@/controllers/page/page.typedefs';
import { setDisplayName } from '@/controllers/page/setDisplayName';
import { I18N_CODES } from '@/lib/constants/general';

interface WithNamespaces{
  (namespaces: Array<I18N_CODES>): (
    PageComponent: NextPage<PageProps>
  ) => NextPage<PageProps>;
}
export const withNamespaces: WithNamespaces = (
  namespaces,
) => (
  PageComponent,
) => {
  const WithNamespaces = (pageProps: PageProps) => (
    <PageComponent {...pageProps} />
  );

  setDisplayName(PageComponent, WithNamespaces, 'withNamespaces');

  const getInitialProps: GetInitialProps = async (ctx) => {
    let pageProps: PageProps = {};

    if (PageComponent.getInitialProps) {
      pageProps = await PageComponent.getInitialProps(ctx);
    }

    const { namespacesRequired = [] } = pageProps;

    return {
      ...pageProps,
      namespacesRequired: [
        ...new Set([
          ...namespacesRequired,
          ...namespaces,
        ]),
      ],
    };
  };

  WithNamespaces.getInitialProps = getInitialProps;

  return WithNamespaces;
};
