import React, {
  createContext, FC, useCallback, useContext,
} from 'react';
import { PageType } from '@/components/landing/Promotions/promotions.typedefs';
import { emptyFunction } from '@/lib/helpers/functional';

interface CtaContextContainerProps {
  href: string;
  text: string;
  callback: (data?: Record<string, any>) => void;
  isVisible?: boolean;
  pageType?: PageType;
}

interface CtaContextProps extends CtaContextContainerProps {
  buildHref: (data?: Record<string, any>) => string;
}

const CtaContext = createContext<CtaContextProps>({
  href: '#',
  text: 'Cta',
  callback: emptyFunction,
  isVisible: true,
  buildHref: () => '#',
  pageType: undefined,
});

interface Props {
  value: CtaContextContainerProps;
}

export const CTAContextProvider: FC<Props> = ({
  value,
  children,
}) => {
  const buildHref = useCallback((data?: Record<string, any>): string => {
    if (!data || value.href.includes('#')) {
      return value.href;
    }

    const shouldAppendHost = !value.href.includes('http');
    const baseHostName = 'https://mate.academy';

    const url = new URL(
      value.href,
      shouldAppendHost
        ? baseHostName
        : undefined,
    );

    Object.entries(data).forEach(([key, val]) => {
      if (url.searchParams.has(key)) {
        url.searchParams.delete(key);
      }

      url.searchParams.append(key, val);
    });

    const result = url.toString();

    return shouldAppendHost
      ? result.replace(baseHostName, '')
      : result;
  }, [value]);

  return (
    <CtaContext.Provider
      value={{
        href: value.href,
        text: value.text,
        callback: value.callback,
        isVisible: value.isVisible,
        buildHref,
        pageType: value.pageType,
      }}
    >
      {children}
    </CtaContext.Provider>
  );
};

export const useCtaContext = () => useContext(CtaContext);
