import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconFacebook: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17 3.4981C17 3.36549 16.9473 3.23832 16.8536 3.14455C16.7598 3.05078 16.6326 2.9981 16.5 2.9981H14C12.7411 2.93539 11.5086 3.37349 10.5717 4.21668C9.63485 5.05987 9.06978 6.2396 9 7.4981V10.1981H6.5C6.36739 10.1981 6.24021 10.2508 6.14645 10.3445C6.05268 10.4383 6 10.5655 6 10.6981V13.2981C6 13.4307 6.05268 13.5579 6.14645 13.6517C6.24021 13.7454 6.36739 13.7981 6.5 13.7981H9V20.4981C9 20.6307 9.05268 20.7579 9.14645 20.8517C9.24021 20.9454 9.36739 20.9981 9.5 20.9981H12.5C12.6326 20.9981 12.7598 20.9454 12.8536 20.8517C12.9473 20.7579 13 20.6307 13 20.4981V13.7981H15.62C15.7312 13.7997 15.8397 13.7642 15.9285 13.6972C16.0172 13.6302 16.0811 13.5355 16.11 13.4281L16.83 10.8281C16.8499 10.7542 16.8526 10.6768 16.8378 10.6017C16.8231 10.5266 16.7913 10.4559 16.7449 10.3951C16.6985 10.3342 16.6388 10.2848 16.5704 10.2507C16.5019 10.2165 16.4265 10.1985 16.35 10.1981H13V7.4981C13.0249 7.25058 13.1411 7.02121 13.326 6.85479C13.5109 6.68838 13.7512 6.59685 14 6.5981H16.5C16.6326 6.5981 16.7598 6.54542 16.8536 6.45165C16.9473 6.35789 17 6.23071 17 6.0981V3.4981Z" fill="currentColor" />
  </BaseIcon>
);
