import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconYoutube: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.1371 6.86467C21.88 5.74306 20.9665 4.91556 19.8668 4.79212C17.2627 4.50018 14.627 4.49866 12.0034 4.50018C9.37931 4.49866 6.74312 4.50018 4.13901 4.79212C3.03996 4.91556 2.12713 5.74306 1.87003 6.86467C1.50412 8.46197 1.5 10.2056 1.5 11.8501C1.5 13.4947 1.5 15.238 1.86591 16.8351C2.12258 17.9565 3.03563 18.784 4.13532 18.9077C6.73922 19.1998 9.37519 19.2013 11.9992 19.1998C14.6237 19.2013 17.2591 19.1998 19.8627 18.9077C20.9618 18.7842 21.8755 17.9567 22.1326 16.8351C22.4987 15.2378 22.5 13.4944 22.5 11.8501C22.5 10.2056 22.503 8.46197 22.1371 6.86467ZM9.29 15.1235C9.29 12.7906 9.29 10.4821 9.29 8.14891C11.5156 9.31516 13.7265 10.4736 15.9662 11.647C13.7334 12.81 11.5215 13.9614 9.29 15.1235Z"
      fill="currentColor"
    />
  </BaseIcon>
);
