import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconTwitter: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.41853 20.993C9.14254 21.0487 10.8606 20.7704 12.4712 20.1745C14.0818 19.5787 15.5523 18.6774 16.7957 17.524C18.0391 16.3706 19.0303 14.9884 19.7105 13.4591C20.3908 11.9298 20.7464 10.2845 20.7564 8.6204C21.5465 7.67717 22.1331 6.5911 22.4819 5.42601C22.5079 5.33396 22.5058 5.23661 22.4759 5.14569C22.4459 5.05476 22.3893 4.97414 22.3129 4.91352C22.2365 4.85291 22.1436 4.81489 22.0453 4.80404C21.9471 4.79319 21.8477 4.80998 21.7591 4.85237C21.3457 5.04432 20.8804 5.10633 20.4286 5.02965C19.9768 4.95297 19.5613 4.74147 19.2407 4.42496C18.8314 3.99256 18.3363 3.64383 17.7848 3.39941C17.2332 3.15499 16.6365 3.01986 16.0298 3.00203C15.4231 2.9842 14.8189 3.08403 14.2529 3.2956C13.687 3.50718 13.1708 3.82619 12.735 4.23374C12.1383 4.79125 11.7012 5.4882 11.4663 6.25687C11.2314 7.02553 11.2065 7.83975 11.3942 8.6204C7.48848 8.84536 4.80692 7.05695 2.66167 4.60492C2.59723 4.53451 2.51292 4.48373 2.41906 4.45878C2.32519 4.43383 2.22582 4.43578 2.13309 4.46441C2.04035 4.49304 1.95827 4.5471 1.89685 4.61999C1.83544 4.69288 1.79736 4.78145 1.78725 4.87487C1.37857 7.06193 1.67338 9.31726 2.63208 11.338C3.59079 13.3587 5.16747 15.048 7.15037 16.179C5.82083 17.65 3.95373 18.5687 1.93882 18.7435C1.83087 18.7607 1.73122 18.8101 1.65382 18.8848C1.57641 18.9594 1.52513 19.0555 1.50714 19.1596C1.48915 19.2637 1.50536 19.3707 1.55349 19.4655C1.60163 19.5603 1.67929 19.6382 1.77559 19.6883C3.52854 20.5334 5.45893 20.9798 7.41853 20.993Z"
      fill="currentColor"
    />
  </BaseIcon>
);
