type EntityWithSlug = {
  slug: string;
};

const excludeSlugsSet = new Set([
  'search-engine-optimization',
]);

// TODO: remove this function when mini-course is implemented https://app.clickup.com/t/24383048/ACQ-15374
export const excludeEntitiesFromList = <T extends EntityWithSlug>(
  entities: T[],
): T[] => (
    entities.filter((entity) => !excludeSlugsSet.has(entity.slug))
  );
